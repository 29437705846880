<template>
  <body class="app">
    <div class="card-box-container">
        <div class="card-box-text-faq">
          <div class="faq-list-container">
            <ul class="faq-list">
              <h2 class="faq-title">FAQ</h2>
              <li class="faq-list-question" id="1">
                Question about payment?
                <span class="arrow-indicator" id="arrow-indicator-1"
                  ><img src="https://raw.githubusercontent.com/mitow7821/FAQ-accordion-card/52073fb304fb21f2cab980c1ff7296b3b41b0045/images/icon-arrow-down.svg" id="arrow-1"
                /></span>
                <p class="faq-answer" id="faq-answer-1">If you have question or concern about your payment, please reach out to our <a href="mailto:support@melvinchng.com">support team</a>. We will reach out to you within 24-48 hours.</p>
              </li>
              <hr />
              <li class="faq-list-question" id="2">
                What is the refund policy?
                <span class="arrow-indicator" id="arrow-indicator-2"
                  ><img src="https://raw.githubusercontent.com/mitow7821/FAQ-accordion-card/52073fb304fb21f2cab980c1ff7296b3b41b0045/images/icon-arrow-down.svg" id="arrow-2"
                /></span>
                <p class="faq-answer" id="faq-answer-2">
                  We do not provide any refund and all sales are final. You may reach out to our <a href="mailto:support@melvinchng.com">support team</a> for pre-purchase and post-purchase questions.
                </p>
              </li>
              <hr />
            </ul>
          </div>
        </div>
    </div>
  </body>
</template>

<script>
const handleClick = () => {
  document.addEventListener("click", (e) => {
    if (e.target.className === "faq-list-question") {
      let question = document.getElementById(e.target.id.toString());
      let answer = document.getElementById('faq-answer-'+e.target.id.toString());

      let arrow = document.getElementById('arrow-'+e.target.id.toString());
      console.log(arrow);
      if (answer.style.display === "block") {
        answer.style.display = "none";
        arrow.classList.remove("active-arrow");
        question.style.fontWeight = "normal";
      } else {
        answer.style.display = "block";
        arrow.classList.add("active-arrow");
        question.style.fontWeight = "bold";
      }
    }
  });
};
handleClick();
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@700&display=swap');
.app {
  height: 100vh;
  background: linear-gradient(hsl(273, 75%, 66%), hsl(240, 73%, 65%));
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}
.card-box-container {
  width: 90%;
  max-width: 750px;
  min-height: 420px;
  height: auto;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  overflow: hidden;
}
.card-box-text-faq {
  margin-top: 30px;
}
.faq-title {
  text-align: start;
  margin-bottom: 30px;
}
.faq-list {
  list-style-type: none;
  padding-left: 40px;
  padding-right: 40px;
}
.faq-list-question {
  color: darkslategray;
}
.faq-list-question:hover {
  color: palevioletred;
  cursor: pointer;
}
.faq-answer {
  color: lightslategray;
  display: none;
}
hr {
  background-color: hsl(240, 5%, 91%) !important;
}
.active-arrow {
  transform: rotateY(180deg);
}
</style>